class QLearning {
    constructor(gamma, alpha){
        this.gamma = gamma || 0.8
        this.alpha = alpha || 0.8

    }
    chooseMove(board, player) {
        console.log('chooseMove');
        let QTable = [];
        let times = 0;
        while (times++ < 2) {
            let hash = this.getHash(board);
            let boardCopy = JSON.parse(JSON.stringify(board));
            console.log(hash);
            console.log(hash);
            hash = this.randomGame(boardCopy, player, hash);
            console.log(hash);
            this.statesUpdate(boardCopy, player, hash, QTable);
            // this.updateEstimations();
        }
        return [1, 1];
    }

    getHash(board) {
        let state = '';
        for (let x = 0; x < 3; x++) {
            for (let y = 0; y < 3; y++) {
                state += board[x][y] ? 'x' : '0';
            }
        }
        return state;
    }

    getRandom(board) {
        let randomIndex, row, col;
        do {
            randomIndex = Math.floor(Math.random() * 9);
            row = parseInt(randomIndex / 3);
            col = randomIndex % 3;
        } while (board[row][col] !== 0);
        return [row, col];
    }

    randomGame(board, turn, hash) {
        console.log('randomGame');
        let row, col;
        // base step
        const countFreeSlots = board.reduce((currentCount, row) => currentCount + row.length, 0);
        if (countFreeSlots === 0) {
            return hash;
        }
        if (this.checkResult(board)) {
            return hash;
        }
        // recursion
        [row, col] = this.getRandom(board);
        board[row][col] = turn;
        let maxNumber = 0;
        for (let i = 0; i < hash.length; i++) {
            if (hash[i] === 'x') continue;
            maxNumber = Math.max(hash[i], Number(maxNumber));
        }
        hash = this.replaceChar(hash, maxNumber+1, 3*row+col);
        turn = turn === 1 ? 2 : 1;
        return this.randomGame(board, turn, hash);
    }

    checkResult(board) {
        return this.checkWinner(board, 0, 1, 2)
            || this.checkWinner(board, 3, 4, 5)
            || this.checkWinner(board, 6, 7, 8)
            || this.checkWinner(board, 0, 3, 6)
            || this.checkWinner(board, 1, 4, 7)
            || this.checkWinner(board, 2, 5, 8)
            || this.checkWinner(board, 0, 4, 8)
            || this.checkWinner(board, 6, 4, 2)
            ;
    }

    checkWinner(board, p1, p2, p3) {
        let x1, y1, x2, y2, x3, y3;
        x1 = parseInt(p1 / 3);
        y1 = p1 - 3 * x1;
        x2 = parseInt(p2 / 3);
        y2 = p2 - 3 * x2;
        x3 = parseInt(p3 / 3);
        y3 = p3 - 3 * x3;
        if (board[x1][y1] === board[x2][y2] && board[x2][y2] === board[x3][y3]) {
            return board[x1][y1];
        }

        return 0;
    }

    replaceChar(origString, replaceChar, index) {
        let firstPart = origString.substr(0, index);
        let lastPart = origString.substr(index + 1);
        let newString = firstPart + replaceChar + lastPart;
        return newString;
    }

    statesUpdate(board, turn, hash, QTable) {
        console.log('statesUpdate');
        console.log(hash);
        console.log(QTable);
        let currentValue = QTable[hash] ? QTable[hash] : 0;
        let reward = this.checkResult(board);
        let maxNextValue = this.optimalNextValue(board, hash, QTable);
        QTable[hash] = (1 - this.alpha) * currentValue + this.alpha * (reward + this.gamma * maxNextValue);
    }

    optimalNextValue(board, hash, QTable) {
        console.log('optimalNextValue');
        let maxIndex = 0;
        let neightborhood = [];
        for (let i = 0; i < hash.length; i++) {
            if (hash[i] === 'x') {
                continue;
            }
            if (hash[i] === '0') {
                neightborhood.push(i);
            }
            maxIndex = Math.max(maxIndex, hash[i]);
        }
        let maxValue = 0;
        neightborhood.forEach((item, items, array) => {
            maxValue = Math.max(maxValue, hash[item] ? hash[item] : 0);
        });
        return maxValue;
    }
}

export {
    QLearning
};